import mt1 from '../../Images/Ourteam/team/6.jpg'
import mt3 from '../../Images/Ourteam/team/21.jpg'
import mt4 from '../../Images/Ourteam/team/30.jpg'
import mt5 from '../../Images/Ourteam/team/23.jpg'
import mt6 from '../../Images/Ourteam/team/27.jpg'


const products = [
    {
        id: "1",
        img: mt1,
        Mname:"MAMTA KANYAL",
        designation:"Senior Manager Projects & Operations",
        
    },
    {
        id: "2",
        img: mt3,
        Mname:"AVNISH MISHRA",
        designation:"Project Manager"
    },
    {
        id: "3",
        img: mt4,
        Mname:"JAI KISHAN KUMAR",
        designation:"Project Coach"
    },
    {
        id: "4",
        img: mt5,
        Mname:"VINOD KUMAR",
        designation:"Software Engineering Manager"
    },
    {
        id: "5",
        img: mt6,
        Mname:"KANISHKA AGARWAL",
        designation:"Social Media Manager"
    }

]

export default products