import React from "react";
import productsData from './DevsTeamData'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col } from 'react-bootstrap'
import { Link } from "react-router-dom";

const Products = () => {

  const products = productsData.map((product) => {

    return (
      <>

<Col xs={12} md={2}>
                                <div className="wpr-member-media">
                                    <img src={product.img} alt={product.Dname} className="img-fluid" />
                                </div>
                                <div className="wpr-member-content">
                                    <h3 className="wpr-member-name">{product.Dname}</h3>
                                    <div className="wpr-member-job">{product.Ddesignation}</div>
                                    <div className="wpr-member-divider"></div>
                                    <Link className="btn-sm btn btn-success" style={{display:"none"}} to={`/OurTeamDevs/${product.Dname.replace(/\s/g, "-").toLowerCase()}`}>Check Visiting Card</Link>
                                </div>
                            </Col>




      </>

    );
  });

  return (
    <>

      
          {products}
       
    </>
  );
};

export default Products;
