import React from "react";
import "../../CustomCss/CustomStyle.css";
import "../../CustomCss/Margins.css";
import { useParams, Link } from "react-router-dom";
import productsData from "./ManagementTeamData";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import NauApp1 from "../../Images/Mockups/InspectNau-Top.png";
import OrionWideLogo from '../../Images/OrionLogo.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  } from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/fontawesome-svg-core";
import {} from "@fortawesome/fontawesome-free-solid";
import {} from "@fortawesome/fontawesome-free";
import { } from "@fortawesome/fontawesome-free-regular";
import { faFacebook, faInstagram, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
function ProductDetail() {
  const { productId } = useParams();
  const thisProduct = productsData.find(
    (prod) => prod.Mname.replace(/\s/g, "-").toLowerCase() === productId
  );

  return (
    <Container>
      <Row className="m-r-0 m-l-0">
       
        <div className="page-content">
          <header id="site_header" className="header mobile-menu-hide">
          <img src={OrionWideLogo} alt='logo' className="site_headerLogo" />
            <div className="header-content">
              <div className="header-photo">
                <img src={thisProduct.img} alt={thisProduct.Mname} />
              </div>
              <div className="header-titles">
                <h2>{thisProduct.Mname}</h2>
                <h4>{thisProduct.designation}</h4>
               
                <div>
                  <a style={{margin:"0px !important", color:"white"}} className="mt-2 mb-2" href="https://www.orionmarineconcepts.com">www.orionmarineconcepts.com</a>
                </div>
              <div className="social-links">
              <ul>
                <li><a href="www.orionmarineconcepts.com" target="_blank"><FontAwesomeIcon icon={faXTwitter}/></a></li>
                <li><a href="www.orionmarineconcepts.com" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a></li>
                <li><a href="www.orionmarineconcepts.com" target="_blank"><FontAwesomeIcon icon={faInstagram}/></a></li>
                <li><a href="www.orionmarineconcepts.com" target="_blank"><FontAwesomeIcon icon={faFacebook}/></a></li>
              </ul>
            </div>
           
            <div className="social-links">
              <button className="btn btn-info pull-right mt-2 mb-3" style={{padding:"0 10px", borderRadius:"50px", color:"white"}}>Save Visiting Card</button>
            </div>
             </div>
            </div>

           
          </header>
          <div className="content-area">
            <div className="animated-sections">
              <section
                data-id="about-me"
                className="animated-section ps ps--theme_default ps--active-y section-active"
                data-ps-id="776c5061-ec44-ace8-2a44-be5e2ab2ac25"
              >
                <div className="section-content">
                  <div className="row">
                    <div className="col-xs-12 text-start" style={{padding:"0"}}>
                      
                     <p>Orion Marine Concepts provides innovative maritime software solutions, including fleet management, electronic logbooks, and maintenance systems, ensuring efficiency, compliance, and safety for global shipping operations.</p>
                     
                    </div>
                   
                  </div>
                  <Row>
                    <Col xs={6}>
                   <Row>
                    <Col xs={6}>
                    <Link className="Maritime_Solutions" to="/products/fleet-management">
                        <img
                          src="https://orionmarineconcepts.netlify.app/static/media/fleet1.60d23854c2a2cb9de571.jpg"
                          alt="ERP_Orion"
                        />
                      </Link>

                     
                      </Col>
                    <Col xs={6}>
                    <Link className="Maritime_Solutions" to="/products/Crew-Management-System">
                        <img
                          src="https://orionmarineconcepts.netlify.app/static/media/CrewBg1.2b4f9b115790cb72f6af.jpg"
                          alt="ERP_Orion"
                        />
                      </Link>
                    </Col>
                   </Row>

                    <img
                src={NauApp1}
                alt="Products"
                className="img-fluid"
              />
                   
                      </Col>
                    <Col xs={6}>
                    <img src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmd5ZWl0YjZrcmI1cjNiMmE3ZG5jNHRhaWl2YXIwZHNnOWlrdjJzayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/0l7BAM7JgBaAnEypGA/giphy.webp" alt='gif' Name="img-fluid"/>
                    </Col>
                  </Row>
                  
                </div>
              </section>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default ProductDetail;
