import mtDev1 from '../../Images/Ourteam/team/24.jpg'
import mtDev2 from '../../Images/Ourteam/team/15.jpg'
import mtDev3 from '../../Images/Ourteam/team/11.jpg'
import mtDev4 from '../../Images/Ourteam/team/17.jpg'
//import mtDev5 from '../../Images/Ourteam/team/22.jpg'
import mtDev6 from '../../Images/Ourteam/team/18.jpg'
import mtDev7 from '../../Images/Ourteam/team/26.jpg'

const products = [
    {
        id: "1",
        img: mtDev1,
        Dname:"AKHILESH PANDEY",
        Ddesignation:"Senior Software Developer"
    },
    {
        id: "2",
        img: mtDev2,
        Dname:"MURLI KUMAR",
        Ddesignation:"Senior UI Developer"
    },
    {
        id: "3",
        img: mtDev3,
        Dname:"KRITESH POKHRIYAL",
        Ddesignation:"Senior Software Developer"
    },
    {
        id: "4",
        img: mtDev4,
        Dname:"PRAMENDU THAKUR",
        Ddesignation:"Software Developer"
    },
    // {
    //     id: "5",
    //     img: mtDev5,
    //     Dname:"DEEPANSHU YADAV",
    //     Ddesignation:"Software Developer"
    // },
    {
        id: "6",
        img: mtDev6,
        Dname:"SAGAR BISHNOI",
        Ddesignation:"Business Intelligence Analyst"
    },
    {
        id: "7",
        img: mtDev7,
        Dname:"SAKSHI SHARMA",
        Ddesignation:"Jr. Developer"
    }

]

export default products