import mtDev1 from '../../Images/Ourteam/team/31.jpg'
import mtSupp1 from '../../Images/Ourteam/team/12.jpg';
import mtSupp2 from '../../Images/Ourteam/team/2.jpg';
import mtSupp3 from '../../Images/Ourteam/team/5.jpg';
import mtSupp4 from '../../Images/Ourteam/team/16.jpg';
import mtSupp5 from '../../Images/Ourteam/team/20.jpg';
import mtSupp6 from '../../Images/Ourteam/team/9.jpg';

const products = [
    {
        id: "1",
        img: mtDev1,
        Sname:"HARISH SHET",
        Sdesignation:"Project Lead"
    },
    {
        id: "2",
        img: mtSupp1,
         Sname:"SHRISTI KARWASARA",
        Sdesignation:"Support Team Lead"
    },
    {
        id: "3",
        img: mtSupp2,
         Sname:"ASTHA BHAMBU",
        Sdesignation:"Senior Implementation Engineer"
    },
    {
        id: "4",
        img: mtSupp3,
        Sname:"RIYA BHAMBU",
        Sdesignation:"Key Accounts Executive"
    },
    {
        id: "5",
        img: mtSupp4,
         Sname:"MEHUL SHARMA",
        Sdesignation:"Implementation Engineer"
    },
    {
        id: "6",
        img: mtSupp5,
         Sname:"AKASH MISHRA",
        Sdesignation:"Technical Support Executive"
    },
    {
        id: "7",
        img: mtSupp6,
        Sname:"KHUSHBOO SINGH",
        Sdesignation:"Assistant Executive"
    }
]

export default products