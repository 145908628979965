import './App.css';
import Header from './Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from "./Components/ScrollToTop";
import News from './Pages/News';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Products from './Pages/Products';
import VesselManagement from './Pages/VesselManagement';
import ContactUsForm from './Pages/ContactUs';
import FooterComponent from './Footer';
import ProductDetail from './Components/BlogComponents/ProductDetail';
import ManagementDetail from "./Components/OurTeamManagement/ManagementTeamVCPage";
import DevsDetail from "./Components/OurTeamDevs/DevsTeamVCPage";
import SupportDetail from "./Components/OurTeamSupport/SupportTeamVCPage";
import NauServer from './Pages/Nau2-1';
//Product Pages
import EDMS from './Products/ElectronicDocumentManagementSystem';
import VCertificate from './Products/VCMS';
import ECloud from './Products/Ecloud';
import ShipCrew from './Products/ShipCrewManagement';
import PerformanceMonitoring from './Products/PerformanceMonitoringSoftware';
import PMSInventory from './Products/PurchaseProcurementMaintenance';
import OInvent from './Products/InventoryManagementSystem';
import QHSSE from './Products/RiskManagementSystem';
import Marpole from './Products/ElectronicMarpolSealLogbook';
import PageNotFound from './Pages/PageNotFound';
import WasteDisposal from './Products/ElectronicWasteDisposalLogBook';
import VoyageDataRecorder from './Products/VoyageDataInformation';
import FPMApp from './Products/Fleet PerformanceMobileApp';
import ScheduleDemo from './Pages/ScheduleDemo';
import UnderCounstruction from './Pages/ComingSoon';
import OurTeam from './Pages/OurPeople';
import NauAppRegistration from './Pages/nau_app_registration';
import TypeApproved from './Pages/type-approved';
import OrionPowerBi from './Pages/OrionBi';
// import OrionVr from './Pages/NeptunesNest';
import InspectNau from './Pages/InspectNau';
import MSbhr from './Components/VIsitingCard/CaptMohitSabharwal';
import MMKL from './Components/VIsitingCard/MamtaKanyalVC';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ScrollButton from './Components/ScrollTopButton';
import OrionMarineApps from './Pages/OrionApps';

function App() {
  const location = useLocation();
// Convert pathname to lowercase to ensure case-insensitive matching
const path = location.pathname.toLowerCase();

// Define paths where header and footer should be hidden
const hideHeaderFooter = path.startsWith("/oursupportteam") || 
                         path.startsWith("/ourmanagementteam") || 
                         path.startsWith("/ourteamdevs") ||
                         path.startsWith("/mamtakanyal") ||
                         path.startsWith("/captmohitsabharwal");

return (
    <>
    
    <div className="App">
      
       {/* Conditionally render the Header */}
       {!hideHeaderFooter && <Header />}
      <Routes>
        <Route exact index path='/' element={<Home />}></Route>
        <Route exact path='/about' element={<AboutUs />}></Route>
        <Route exact path='/CaptMohitSabharwal' element={<MSbhr />}></Route>
        <Route exact path='/MamtaKanyal' element={<MMKL />}></Route>
        <Route exact path='/vesselManagement' element={<VesselManagement />}></Route>
        <Route exact path='/product' element={<Products />}></Route>
        <Route exact path='/products/Electronic-Document-Management-System' element={<EDMS />}> </Route>
        <Route exact path='/products/Vessel-Certificate-Management-System' element={<VCertificate />}> </Route>
        <Route exact path='/products/ECloud' element={<ECloud />}> </Route>
        <Route exact path='/products/Crew-Management-System' element={<ShipCrew />}></Route>
        <Route exact path='/products/fleet-management' element={<PerformanceMonitoring />}></Route>
        <Route exact path='/products/Planned-Maintenance-System' element={<PMSInventory />}></Route>
        <Route exact path='/products/supply-Chain' element={<OInvent />}></Route>
        <Route exact path='/products/QHSSE' element={<QHSSE />}></Route>
        <Route exact path='/products/Electronic-Marpol-Seal-Log-book' element={<Marpole />}></Route>
        <Route exact path='/products/Electronic-Waste-log-Book' element={<WasteDisposal />}></Route>
        <Route exact path='/products/Voyage-Data-Recorder' element={<VoyageDataRecorder />}></Route>
        <Route exact path='/products/Fleet-Performance-Mobile-App' element={<FPMApp />}></Route>
        <Route exact path='/News' element={<News />}></Route>
        <Route exact path='/News/:productId' element={<ProductDetail />}></Route>
        <Route path="/OurManagementTeam/:productId" element={<ManagementDetail />} />
        <Route path="/OurTeamDevs/:productId" element={<DevsDetail />} />
        <Route path="/OurSupportTeam/:productId" element={<SupportDetail />} />
        <Route exact path='/contact' element={<ContactUsForm />}></Route>
        <Route exact path='/NauServer' element={<NauServer />}></Route>
        <Route exact path='/ScheduleDemo' element={<ScheduleDemo />}></Route>
        <Route exact path='/UnderCounstruction' element={<UnderCounstruction />}></Route>
        <Route exact element={<OurTeam />} path='/OurTeam'></Route>
        <Route exact path="/nau_app_registration" element={<NauAppRegistration />}></Route>
        <Route exact path='/type-approved' element={<TypeApproved />}></Route>
        <Route path="*" element={<PageNotFound />} />
        <Route exact path="/Orion-Bi" element={<OrionPowerBi />}></Route>
        {/* <Route exact path="/Neptunes-Nest" element={<OrionVr />}></Route> */}
        <Route exact path="/Inspect-Nau-App" element={<InspectNau />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/Orion-Marine-Apps" element={<OrionMarineApps />}></Route>
      </Routes>
      {/* Conditionally render the Footer */}
      {!hideHeaderFooter && <FooterComponent />}
      <ScrollToTop />
      <ScrollButton />
    </div>
    </>
  );
}

export default App;
